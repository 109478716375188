import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
/* import { faUtensils, faCheckSquare, faMegaphone } from "@fortawesome/free-solid-svg-icons" */
import { faComments } from "@fortawesome/free-solid-svg-icons"

import SEO from "../../components/seo"

const Faq = ({ data, location }) => (
  <Layout>
    <SEO
      pagetitle="よくある質問"
      pagedesc="micro:bit（マイクロビット）を中心にプログラミング教育教材を提供いたします。"
      pagepath={location.pathname}
      pageimg={data.faq.childImageSharp.original.src}
      pageimgw={data.faq.childImageSharp.original.width}
      pageimgh={data.faq.childImageSharp.original.height}
    />
    <div className="eyecatch">
      <figure>
        <Img
          fluid={data.faq.childImageSharp.fluid}
          alt="micro:bit（マイクロビット）を中心にプログラミング教育教材を提供いたします。"
          loading="eager"
          durationFadeIn={100}
        />
      </figure>
    </div>
    <article className="content">
      <div className="container">
        <aside className="info">
          <div className="subtitle">
            <h1 className="bar"><FontAwesomeIcon icon={faComments} />よくある質問</h1>
          </div>
        </aside>
          <div className="postbody">
            <p>
              よくある質問を掲載しています。その他のご確認は「お問合せ」フォームよりご連絡ください。
            </p>
            <br />

            <div className="inner">
              <h2>言葉について</h2>
              <h3>・「STEAM」とは何でしょうか？</h3>
              <p>
                STEAMとは、Science（科学）、Technology（技術）、Engineering（工学）、Art（芸術）、Mathematics（数学）の5つの単語の頭文字を組合せた造語です。
                <br />
                世界で注目されており、子どもは自分の好奇心に基づき、実社会の問題発見し、自分の手で問題解決やイノベーションを行うための教育手法です。
                プログラミング教育もその一環だとされています。
              </p>
              <br />
              <h3>・「プログラミング教育」とは何でしょうか？</h3>
              <p>
                プログラミング等のIT関連スキルに関する教育をプログラミング教育とされています。
                <br />
                AI・IoTなど、デジタル化の時代に、社会構造が大きく変わろうとしています。あらゆる業界がプログラミングスキルが必須となってきています。
                その流れに遅れないように、2020年より義務教育におけるプログラミング教育が順次開始されています。
              </p>
              <br />

              <h2>教育教材ソリューションについて</h2>
              <h3>・ソリューションの価格を教えてください。</h3>
              <p>
                ソリューションの価格は都度お見積となっています。お問合せください。<br />
{/*                 当社定型のソリューションをご提供する場合、ご提案が無償となっております。
                カスタマイズが必要な場合、都度お見積となっています。お問合せください。 */}
              </p>
              <br />
              <h3>・一緒に教材用デバイスやカリキュラムを開発することができますか？</h3>
              <p>
                取扱製品がご要望にフィットしない場合や独自なデバイスやカリキュラムが必要な場合、
                当社のノウハウやチャネルを活かして、ご一緒に新たに開発することも可能です。
              </p>
              <br />
              <h3>・製品のデモを行ってもらえますか？</h3>
              <p>学校・法人様向けに、デモを実施することが可能です。お気軽にお問合せください。</p>
              <br />
              <h3>・ソリューションについての詳しい問合せができますか？</h3>
              <p>詳しいお問合せは、「お問合せ」フォームよりご連絡ください。</p>

              <h2>取扱商品について</h2>
              <h3>・商品は正規品でしょうか？</h3>
              <p>当社は、micro:bit本体、DFRobot社製品、Yahboom社製品などの国内正規販売店です。</p>
              <br />
              <h3>・micro:bit本体を購入することができますか？</h3>
              <p>
                当社はmicro:bitの国内正規販売店であり、弊社ネットストアにてご購入が可能です。
                <br />
                大量仕入れが必要な場合、問合せフォームよりご連絡ください。
              </p>
              <br />
              <h3>・micro:bit関連製品のみを扱っていますか？</h3>
              <p>
                当社では、micro:bit関連製品を主に扱っております。
                <br />
                その他、Raspberry Pi、Arduino、Jetsonなどの関連製品も取り扱っております。
              </p>
              <br />
              <h3>・取扱のない商品を購入することができますか？</h3>
              <p>
                学校・法人様向けでは、お探しの製品をリクエストすることも可能です。「お問合せ」フォームよりお問合せください。
                <br />
                ※製品によっては取扱ができない場合がございます。あらかじめご了承ください。
              </p>
              <br />
              <h3>・商品はどこから発送されますか？</h3>
              <p>商品の全ては日本国内より発送します。</p>

              <h2>お支払い方法について</h2>
              <h3>・どのような支払い方法がありますか？</h3>
              <p>
                当社ウェブストアでは、クレジットカードをはじめ、様々なお支払い方法に対応しております。
                <br />
                請求書による支払いをご希望の場合、「お問合せ」フォームよりお問合せください。
              </p>
              <br />
              <h3>・見積書、請求書、納品書、領収書の発行ができますか？</h3>
              <p>
                ウェブストアでのご購入について、納品書は発送する荷物に同梱します。領収書は管理ページよりダウンロードが可能です。
                <br />
                学校・法人様向けでは、個別に見積書、請求書、納品書、領収書の発行が可能です。
              </p>

            </div>
          </div>
      </div>
    </article>
  </Layout>
)

export const query = graphql`
  query {
    faq: file(relativePath: { eq: "faq.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
        original {
          src
          height
          width
        }
      }
    }
  }
`
export default Faq
